import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/high-performer/high-performer-g2.png';
import styled from 'styled-components';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`;

const Content = () => {
  return (
    <Container>
      <p>
        <strong>
          We are proud to have been honored by G2 Crowd. It is one of the largest and award-winning
          portals gathering reviews of products and services from various industries.
        </strong>
      </p>
      <p>
        This time{' '}
        <a rel="noopener nofollow noreferrer" target="_blank" href="https://www.g2.com/">
          G2
        </a>{' '}
        prepared a report in the Session Replay tool category, where out of 53 companies, ten were
        awarded in this category. We were awarded the <strong>High Performer badge</strong>. This
        award is given to products that are appreciated by customers and has been on the market for
        a short time compared to other products in this category. LiveSession has been recognized as
        a product with a very high potential, which at an early stage of its existence on the market
        (since 2019), is placed among the leaders.
      </p>

      <p>
        LiveSession received a satisfaction rating of between{' '}
        <strong>
          90% and 100% in terms of ease of set-up, ease of use, ease of administration, and
          supported quality
        </strong>
        . 100% of users rated us 4 or 5 stars, 91% felt we were heading in the right direction, and
        users said they would probably recommend LiveSession at 94%.
      </p>

      <p>
        We want to thank those who have shared their views. It is thanks to our customers that we
        have the strength and motivation to continue to grow and improve our product so that it can
        help many online businesses grow.{' '}
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'LiveSession Named A High Performer in Session Replays, For Fall 2019 by G2 Crowd ',
  url: '/blog/livesession-named-a-high-performer-in-session-replays-for-fall-2019-by-g2-crowd',
  description:
    'We are proud to have been honored by G2 Crowd! It is one of the largest and award-winning portals gathering reviews of products and services from various industries.',
  author,
  img,
  imgSocial: img,
  date: '2019-09-30',
  category: '',
  imgStyle: { borderBottom: '1px solid rgba(0,0,0,0.125)' },
  group: 'Awards',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    descriptionSocial="We are proud to have been honored by G2 Crowd! It is one of the largest and award-winning portals gathering reviews of products and services from various industries."
  />
);
